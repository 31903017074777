import { IProductDto } from "@crunchit/types";
import { useCallback, useState } from "react";

import Counter from "components/ui/Counter";
import useBasketUpdates from "hooks/useBasketUpdates";
import useTranslationText from "hooks/useTranslationText";
import { useBasketSelector } from "store/basket";

import "./PrecheckoutProduct.scss";

interface IProductViewProps {
  product: IProductDto;
}

export default function PrecheckoutProduct(props: IProductViewProps) {
  const { product } = props;

  const { getTranslationText } = useTranslationText();
  const { handleBasketChange } = useBasketUpdates();
  const { basketProducts } = useBasketSelector();

  const [count, setCount] = useState(0);

  const countChanged = useCallback(
    (newCount: number) => {
      setCount(newCount);

      let basketNewCount = newCount;
      const productInBasket = basketProducts.find((b) => b.productId === product.id);

      if (productInBasket) {
        basketNewCount = productInBasket.amount + newCount;
      }

      handleBasketChange({ newCount: basketNewCount, productId: product.id, product });
    },
    [basketProducts, handleBasketChange, product]
  );

  return (
    <li className="precheckout-product">
      <Counter count={count} handleChange={countChanged} />
      <p className="precheckout-product-name">{getTranslationText(product.name)}</p>
    </li>
  );
}
