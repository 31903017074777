import { get } from "@crunchit/http-client";
import { ICategoryDto, IMenuDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_MENU_URI || `menu.${getEnvironment()}crunchy.ninja/api`;

const MenuService = {
  getMenus: async (instanceId: string) => {
    return get<IMenuDto[]>(`${api}/menu?instanceId=${instanceId}`, getToken());
  },
  getCategories: async (menuId: string, instanceId: string) => {
    return get<ICategoryDto[]>(`${api}/category/menu/${menuId}?instanceId=${instanceId}`, getToken());
  },
};

export default MenuService;
