import i18n from "i18n";
import { IFrontendSettingsDto } from "@crunchit/types";

import ModuleService from "services/ModuleService";
import CustomerSettingService from "services/CustomerSettingService";
import StorageService from "services/StorageService";
import { frontendSettingsFactory } from "./factory";

export async function getModuleFromDomain() {
  let productionUnitId;
  let moduleId;

  // OBS: We only use the productionUnitId and moduleId since we cannot trust the full module response in this request
  const moduleFromUrlResponse = await ModuleService.getModuleByDomain(window.location.hostname);

  if (moduleFromUrlResponse.isSuccess()) {
    productionUnitId = moduleFromUrlResponse.data.productionUnitId;
    moduleId = moduleFromUrlResponse.data.id;
  }

  return { productionUnitId, moduleId };
}

export async function setupLanguage() {
  let languages: string[] = [];
  let languageToUse = "da-DK";

  const languageSettings = await CustomerSettingService.getLanguage();

  if (languageSettings.isSuccess() && languageSettings.data.defaultLanguage) {
    // Only handling one language per account for now
    languages = languageSettings.data.languages;
    languageToUse = languageSettings.data.defaultLanguage;

    if (languageToUse && languageToUse !== "da-DK" && i18n.language !== languageToUse) {
      i18n.changeLanguage(languageToUse);
    }
  }

  return languages;
}

export async function getMaintenanceMode() {
  let isDownForMaintenance = false;

  if (process.env.NODE_ENV === "production") {
    const maintenanceModeResponse = await StorageService.getMaintenanceMode();

    if (maintenanceModeResponse.isSuccess()) {
      isDownForMaintenance = maintenanceModeResponse.data.isDownForMaintenance;
    }
  }

  return isDownForMaintenance;
}

export async function getFrontendSettings(moduleId: string) {
  const frontendSettingsResponse = await CustomerSettingService.getFrontendSettingsByModule(moduleId);

  if (!frontendSettingsResponse.isSuccess()) {
    throw new Error("Unable to get frontend settings");
  }

  const features = frontendSettingsResponse.data.features || [];
  let settings: Partial<IFrontendSettingsDto> = frontendSettingsFactory();

  features.forEach((feature: { name: string; value: string }) => {
    let { name, value } = feature;

    try {
      value = JSON.parse(value);
    } catch (error) {}

    if (name in settings) {
      const featureObj: { [key: string]: any } = {};
      featureObj[name] = value;

      settings = { ...settings, ...featureObj };
    }
  });

  return settings;
}
