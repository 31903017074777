import { ICheckoutDto, IOrderDto, ISessionItem, IPaymentDto, IProductionUnitDto } from "@crunchit/types";
import { mappedOrderProducts, sortProductLast } from "@crunchit/utilities";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import OrderService from "services/OrderService";
import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";

// The computed properties for the order
interface IOrderProperties {
  deliveryType: string;
  date: string;
  time: string;

  deliveryFee: number;
  discountAmount: number;
  orderTotal: number;
  payments: IPaymentDto[];
  receiptNumber: string | number;
  receiptMessageType: string;

  products: ISessionItem[];
}

// Custom hook for Confirmation page data
export default function useFinalOrder(checkout: ICheckoutDto, productionUnit: IProductionUnitDto | null, orderId?: string) {
  const { appSettings } = useAppSelector();
  const { bagFeeProductId } = useBasketSelector();

  let [order, setOrder] = useState<IOrderDto>();
  let [orderError, setOrderError] = useState(false);

  useEffect(() => {
    const loadOrder = async (orderId: string) => {
      try {
        const finalOrderResponse = await OrderService.getOrderById(orderId);

        if (finalOrderResponse.isSuccess()) {
          setOrder(finalOrderResponse.data);
          return;
        }
      } catch (error) {
        console.error(error);
      }

      // Order not found
      setOrderError(true);
    };

    if (orderId && !order) {
      // orderId is only updated once
      loadOrder(orderId);
    }
  }, [orderId, order]);

  const getOrderProperties = useCallback(
    (order?: IOrderDto) => {
      let properties: IOrderProperties | null = null;

      if (order) {
        const deliveryType = order.delivery && order.delivery.zipCode ? "delivery" : "pickup";

        const orderTimestamp = deliveryType === "delivery" && order.delivery ? order.delivery.deliveryTime : order.orderReadyTime;
        const date = moment(orderTimestamp).format("DD/MM/YYYY");
        const time = moment(orderTimestamp).format("HH:mm");

        let discountAmount = 0;

        if (order.discounts && order.discounts.length) {
          discountAmount = order.discounts.reduce((acc, curr) => {
            return acc + curr.amount;
          }, 0);
        }

        const receiptNumber = appSettings.orderIdType === "external" && order.externalId ? order.externalId : order.receipt.number;

        // Mapping the order products for our orderlist
        let products = mappedOrderProducts(order.products);

        if (bagFeeProductId) {
          products = products.sort(sortProductLast(bagFeeProductId));
        }

        properties = {
          deliveryType,
          date,
          time,

          deliveryFee: order.delivery ? order.delivery.fee : 0,
          discountAmount,
          orderTotal: order.totalAmount,
          payments: order.payments,
          receiptNumber,
          receiptMessageType: appSettings.receiptType,

          products,
        };
      }
      return properties;
    },
    [appSettings, bagFeeProductId]
  );

  const orderProperties = useMemo(() => getOrderProperties(order), [order, getOrderProperties]);

  return {
    order,
    orderError,
    orderProperties,
  };
}
