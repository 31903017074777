import { Link } from "react-router-dom";
import { useAppSelector } from "store/app";
import "./Logo.scss";

interface ILogoProps {
  handleClick: () => void;
}

export default function Logo(props: ILogoProps) {
  const { handleClick } = props;
  const { appSettings } = useAppSelector();

  return (
    <Link to={{ pathname: "/" }} className="logo-container">
      {appSettings.logo && <img loading="lazy" className="logo" onClick={handleClick} src={appSettings.logo} alt="logo" />}
    </Link>
  );
}
