import { IBasketItemDto } from "@crunchit/types";
import moment from "moment";

export function isExpired(createdTime: string) {
  const expirationTime = 120;

  const now = moment.utc(); // createdTime is in utc
  const created = moment.utc(createdTime);
  const expiration = created.add(expirationTime, "minutes");

  return expiration.isBefore(now);
}

// Disabling edit on the cart fee
export const withDisabledCartFee = (products: IBasketItemDto[], cartFeeItemId: number) => {
  let cartFeeIndex = products.length - 1;
  let updatedCartFeeItem = Object.assign({}, products[cartFeeIndex]);

  if (updatedCartFeeItem && updatedCartFeeItem.productId === cartFeeItemId) {
    updatedCartFeeItem.editingIsDisabled = true;
  }

  products[cartFeeIndex] = updatedCartFeeItem;

  return products;
};
