import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";

import "./BasketTotal.scss";

export default function BasketTotal(props: { total: number }) {
  const { total } = props;
  const { t } = useTranslation();

  return (
    <div className="total basket-total">
      <h2 className="total-title">{t("cart:Total")}</h2>
      <h2 className="total-amount">
        {formatPrice(total ? total : 0)}
      </h2>
    </div>
  );
}
