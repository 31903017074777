import { formatPrice } from "@crunchit/utilities";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import Precheckout from "../precheckout/Precheckout";
import chevronSrc from "assets/img/icons/chevron_red.svg";
import OrderList from "components/basket/OrderList/OrderList";
import BasketTotal from "components/basket/totals/BasketTotal";
import useClickAway from "hooks/useClickAway";
import { useBasketSelector } from "store/basket";
import { useMenuSelector } from "store/menu";

import "./FullBasket.scss";

interface IFullBasketProps {
  clickHandlers: {
    basket: () => void;
    continue: () => void;
  };
}

export default function FullBasket(props: IFullBasketProps) {
  const { clickHandlers } = props;

  const { basketProducts, basketProductTotal } = useBasketSelector();
  const { precheckoutCategory } = useMenuSelector();
  const { t } = useTranslation();

  const rootClassName = "expanded-cart";

  const [showPrecheckout, setShowPrecheckout] = useState(false);

  // Allow click away only in desktop view
  function handleClickAway() {
    if (document.body.clientWidth > 700) {
      clickHandlers.basket();
    }
  }

  const handleNextClick = useCallback(() => {
    if (!showPrecheckout && precheckoutCategory) {
      setShowPrecheckout(true);
    } else {
      clickHandlers.continue();
    }
  }, [showPrecheckout, precheckoutCategory, clickHandlers]);

  function handleBackClick() {
    setShowPrecheckout(false);
  }

  useClickAway(rootClassName, handleClickAway);

  return (
    <div className={`${rootClassName}  ${showPrecheckout ? "show-precheckout" : ""}`}>
      <header>
        <h1>{t("cart:CartTitle")}</h1>

        <button onClick={clickHandlers.basket}>
          <i className="exit-icon"></i>
        </button>
      </header>

      <div className="content inner">
        <div className="orderlist-container">
          <OrderList products={basketProducts} showChoices />

          {basketProducts.length > 0 && (
            <>
              <BasketTotal total={basketProductTotal} />

              <div className="cart-cta-button">
                <button className="button button-colored red" onClick={handleNextClick}>
                  {t("cart:GoToCheckout")}
                </button>
              </div>
            </>
          )}
        </div>

        {precheckoutCategory && basketProducts.length > 0 && (
          <div className="precheckout-container">
            <Precheckout basketItemCount={basketProducts.length} basketTotal={basketProductTotal} precheckoutCategory={precheckoutCategory} onBackClick={handleBackClick} onContinueClick={handleNextClick} />
          </div>
        )}
      </div>

      <div className="footer">
        <button className="continue-button button button-colored green" onClick={handleNextClick} disabled={basketProducts.length === 0}>
          {showPrecheckout && <span className="item-count">{basketProducts.length}</span>}
          <span>{showPrecheckout ? t("cart:Checkout") : t("cart:GoToCheckout")}</span>
          <span>{formatPrice(basketProductTotal)} ,-</span>
        </button>

        <button className="close-button" onClick={clickHandlers.basket} style={{ backgroundImage: `url(${chevronSrc})` }}></button>
      </div>
    </div>
  );
}
