import { useEffect } from "react";
import Topbar from "components/navigation/Topbar";
import Sidebar from "components/navigation/Sidebar";
import Basket from "components/basket/Basket";
import { ILayoutOptions } from "models/layout";
import { useAppSelector } from "store/app";
import SubHeader from "../SubHeader";
import useHeaderNavigation from "./useHeaderNavigation";

import "./Header.scss";

interface IHeaderProps extends Partial<ILayoutOptions> {
  onBasketIsOpenChanged: (isOpen: boolean) => void;
  showOverlay: boolean;
}

// A wrapper including the topbar header and expandable cart and navigation
export default function Header(props: IHeaderProps) {
  const { hideBasket, hideSidebar, showOverlay, onBasketIsOpenChanged } = props;

  const { hideNavigation, basketIsOpen, sidebarIsOpen, sidebarIsClosing, clickHandlers } = useHeaderNavigation();
  const { productionUnit } = useAppSelector();

  useEffect(() => onBasketIsOpenChanged(basketIsOpen), [onBasketIsOpenChanged, basketIsOpen]);

  return (
    <header className={`header-container ${basketIsOpen || sidebarIsOpen ? "expanded-header-container" : ""}`}>
      {showOverlay && <div id="header-overlay"></div>}

      <Topbar hideBasket={hideBasket || hideNavigation} hideSidebar={hideSidebar || hideNavigation} clickHandlers={clickHandlers} />

      {sidebarIsOpen && <Sidebar isClosing={sidebarIsClosing} handleCategoryClick={clickHandlers.sidebarCategory} />}

      <SubHeader name={productionUnit?.name} hideBasket={hideBasket || hideNavigation} clickHandlers={clickHandlers} />

      {basketIsOpen && <Basket handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.basket} />}
    </header>
  );
}
