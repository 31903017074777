import { IBasketItemDto } from "@crunchit/types";
import { sortProductLast } from "@crunchit/utilities";
import { useMemo } from "react";

import { useCustomSelector } from "store/useStore";
import { useAppSelector } from "store/app";

import { withDisabledCartFee } from "./utils/helpers";
import { basketFactory } from "./utils/factory";

// Computing basket values from redux state
export default function useSelector(props?: { allowCartFees: boolean }) {
  const allowCartFees = props ? props.allowCartFees : false;

  const state = useCustomSelector((state) => state.basket);

  const { basket } = state;
  const { appSettings } = useAppSelector();

  // Fallback value, to avoid unnecessary checks
  const fallbackBasket = basketFactory(basket);

  const bagFeeProductId = appSettings.bagFeeItem;

  const basketProducts = useMemo(() => {
    let products: IBasketItemDto[] = (basket && basket.items) || [];

    // Filtering and sorting cart fee items
    if (bagFeeProductId) {
      if (!allowCartFees) {
        // Filtering out cart fees unless we're in checkout or similar
        products = products.filter((p: IBasketItemDto) => p.productId !== bagFeeProductId);
      } else {
        // Sorting cart fees last
        products = [...products].sort(sortProductLast(bagFeeProductId));

        // And disabling edit on the cart fee
        products = withDisabledCartFee(products, bagFeeProductId);

        // The cart fee is not allowed to be the last in the list
        if (products.length === 1 && products[0].productId === bagFeeProductId) {
          products = [];
        }
      }
    }

    return products;
  }, [basket, bagFeeProductId, allowCartFees]);

  const basketProductTotal = useMemo(
    () =>
      basketProducts.reduce((previous, current) => {
        return previous + current.totalPrice;
      }, 0),
    [basketProducts]
  );

  const basketProductCount = useMemo(
    () =>
      basketProducts.reduce((previous, current) => {
        return previous + current.amount;
      }, 0),
    [basketProducts]
  );

  return {
    ...state,
    basket: basket || fallbackBasket,
    bagFeeProductId,
    basketProducts,
    basketProductTotal,
    basketProductCount,
  };
}
