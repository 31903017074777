import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBasketDto } from "@crunchit/types";

import { addBasketItem, clearBasket, loadBasket, removeBasketItem, updateBasketItem } from "./thunks";
import { IBasketState, ICriticalError } from "models/store";

/* Basket slice */

const initialState: IBasketState = {
  basket: null,
  hasLoaded: false,
  isLoading: false,
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setBasket: (state, action: PayloadAction<IBasketDto | null>) => {
      state.basket = action.payload;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBasket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadBasket.fulfilled, (state, action) => {
        state.basket = action.payload;
        state.isLoading = false;
        state.hasLoaded = true;
      })
      .addCase(loadBasket.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(addBasketItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBasketItem.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(addBasketItem.fulfilled, (state, action) => {
        state.basket = action.payload;
        state.isLoading = false;
      })

      .addCase(updateBasketItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBasketItem.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(updateBasketItem.fulfilled, (state, action) => {
        state.basket = action.payload;
        state.isLoading = false;
      })

      .addCase(removeBasketItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeBasketItem.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(removeBasketItem.fulfilled, (state, action) => {
        state.basket = action.payload;
        state.isLoading = false;
      })

      .addCase(clearBasket.fulfilled, (state) => {
        state.basket = null;
      });
  },
});

export const slice = basketSlice.reducer;
export const actions = basketSlice.actions;
