import cart from "./cart.json";
import checkout from "./checkout.json";
import common from "./common.json";
import confirmation from "./confirmation.json";
import errors from "./errors.json";
import pagetitles from "./pagetitles.json";
import precheckout from "./precheckout.json";
import products from "./products.json";

export const da = {
  cart: cart,
  checkout: checkout,
  common: common,
  confirmation: confirmation,
  errors: errors,
  pagetitles: pagetitles,
  precheckout: precheckout,
  products: products,
};
