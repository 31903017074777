import { IProductionUnitDto } from "@crunchit/types";
import { useTranslation } from "react-i18next";

import "./RestaurantInfo.scss";

// For the error page and confirmation page
export default function RestaurantInfo(props: { productionUnit: IProductionUnitDto }) {
  const { productionUnit } = props;
  const { t } = useTranslation();

  return (
    <div className="restaurant-info">
      <p className="location-info-text">
        <span className="location-name">{productionUnit.name}</span>

        {productionUnit.address && productionUnit.zipCode && (
          <span className="location-address">
            {productionUnit.address}, {productionUnit.zipCode}
          </span>
        )}

        {productionUnit.phone && (
          <span className="location-phone">
            {t("common:Location.Phone")}: {productionUnit.phone}
          </span>
        )}

        {productionUnit.receiptEmail && (
          <span className="location-email">
            <a href={`mailto:${productionUnit.receiptEmail}`}>{productionUnit.receiptEmail}</a>
          </span>
        )}

        {productionUnit.cvrNumber && (
          <span className="location-cvr">
            {t("common:Location.CVR")}: {productionUnit.cvrNumber}
          </span>
        )}
      </p>
    </div>
  );
}
