import { IChoiceDto, IModifierDto, IProductModifierDto } from "@crunchit/types";
import Choices from "components/menu/Choices";
import { ISelectedChoice } from "models/product";

interface IModifierViewProps {
  title: string;
  modifier: IModifierDto;
  productModifier: IProductModifierDto;
  choices: IChoiceDto[];
  handleAddChoice: (choice: IChoiceDto) => void;
  handleRemoveChoice: (choice: IChoiceDto) => void;
  selectedChoices?: ISelectedChoice[];
}

export default function Modifier(props: IModifierViewProps) {
  const { title, modifier, productModifier, choices, selectedChoices } = props;
  const { handleAddChoice, handleRemoveChoice } = props;

  return (
    <article className="modifier collapsible-section expanded">
      <h1 className="modifier-title" id={modifier.id.toString()}>
        {title}
      </h1>

      <Choices choices={choices} modifierChoices={modifier.choices} selectedChoices={selectedChoices} productModifier={productModifier} handleAddChoice={handleAddChoice} handleRemoveChoice={handleRemoveChoice} />
    </article>
  );
}
