import { get } from "@crunchit/http-client";
import { IBlockedChoiceDto, IBlockedProductDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_WAREHOUSE_URI || `warehouse.${getEnvironment()}crunchy.ninja/api`;

const WarehouseService = {
  getBlockedProducts: async (productionUnitId: number) => {
    return get<IBlockedProductDto[]>(`${api}/blockedProduct?productionUnitId=${productionUnitId}`, getToken());
  },
  getBlockedChoices: async (productionUnitId: number) => {
    return get<IBlockedChoiceDto[]>(`${api}/blockedChoice?productionUnitId=${productionUnitId}`, getToken());
  },
};

export default WarehouseService;
