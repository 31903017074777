import { IChoiceDto, IModifierChoiceDto, IProductModifierDto } from "@crunchit/types";

import useChoices from "./useChoices";
import Choice from "./Choice";
import { ISelectedChoice } from "models/product";

import "./Choices.scss";

interface IChoiceListProps {
  choices: IChoiceDto[];
  modifierChoices: IModifierChoiceDto[];
  selectedChoices?: ISelectedChoice[];
  productModifier: IProductModifierDto;
  handleAddChoice: (choice: IChoiceDto) => void;
  handleRemoveChoice: (choice: IChoiceDto) => void;
}

export default function Choices(props: IChoiceListProps) {
  const { selectedChoices, choices, modifierChoices, productModifier, handleAddChoice, handleRemoveChoice } = props;

  const { getChoice, getSelectedChoice, getChoicePrice } = useChoices(productModifier, modifierChoices, selectedChoices);

  return (
    <ul className="choice-list">
      {modifierChoices.map(function (modifierChoice, index) {
        const choice = getChoice(modifierChoice.choiceId, choices);

        if (!choice) {
          return null;
        }

        const price = getChoicePrice(choice, modifierChoice);
        const isSelected = !!getSelectedChoice(choice.id);

        return <Choice key={index} choice={choice} isSelected={isSelected} price={price} handleAddChoice={handleAddChoice} handleRemoveChoice={handleRemoveChoice} />;
      })}
    </ul>
  );
}
