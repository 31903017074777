import { get } from "@crunchit/http-client";
import { IModuleDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_MODULE_URI || `module.${getEnvironment()}crunchy.ninja/api`;

const ModuleService = {
  getModuleById: async (id: string) => {
    return get<IModuleDto>(`${api}/module/${id}/module`, getToken());
  },
  getModuleByDomain: async (domain: string) => {
    return get<IModuleDto>(`${api}/module/domain/${domain}`);
  },
};

export default ModuleService;
