import { ICheckoutDto } from "@crunchit/types";
import CheckoutService from "services/CheckoutService";

export async function removeDiscountCodes(checkout: ICheckoutDto) {
  let updatedCheckout = checkout;

  if (checkout.discounts && checkout.discounts.length) {
    for (let i = 0; i < checkout.discounts.length; i++) {
      const discountId = checkout.discounts[i].id;

      if (discountId) {
        const checkoutResponse = await CheckoutService.removeDiscount(checkout.id, discountId);

        if (checkoutResponse.isSuccess()) {
          updatedCheckout = checkoutResponse.data;
        }
      }
    }
  }

  return updatedCheckout;
}

export async function removeGiftcards(checkout: ICheckoutDto) {
  let updatedCheckout = checkout;

  if (checkout.giftcards && checkout.giftcards.length) {
    for (let i = 0; i < checkout.giftcards.length; i++) {
      const giftcardId = checkout.giftcards[i].id;

      if (giftcardId) {
        const checkoutResponse = await CheckoutService.removeGiftcard(checkout.id, giftcardId);

        if (checkoutResponse.isSuccess()) {
          updatedCheckout = checkoutResponse.data;
        }
      }
    }
  }

  return updatedCheckout;
}

export async function removeDeliveryInfo(checkout: ICheckoutDto) {
  let updatedCheckout = checkout;

  if (checkout.delivery) {
    try {
      await CheckoutService.removeDeliveryInfo(checkout.id);

      const checkoutResponse = await CheckoutService.getCheckoutById(checkout.id);

      if (checkoutResponse.isSuccess()) {
        updatedCheckout = checkoutResponse.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return updatedCheckout;
}
