import { ICheckoutSectionProps } from "@crunchit/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import SectionWrapper from "./SectionWrapper";
import { Input } from "components/ui/forms";
import { useInputValidation } from "hooks/useInputValidation";

export default function GuestInfo(props: ICheckoutSectionProps) {
  const { index } = props;

  const { t } = useTranslation();
  const { checkoutSession } = useCheckoutSelector();
  const { getInputError } = useInputValidation();
  const dispatch = useCustomDispatch();

  const { name = "", phone = "", email = "" } = checkoutSession.guestInformation;

  const nameError = useMemo(() => getInputError("name", name, "errors:Input.Name"), [name, getInputError]);
  const phoneError = useMemo(() => getInputError("phone", phone, "errors:Input.Phone"), [phone, getInputError]);
  const emailError = useMemo(() => getInputError("email", email, "errors:Input.Email"), [email, getInputError]);

  function handleInputChange(change: { value: string; name: string }) {
    const { value, name } = change;

    let updatedGuestInfo = Object.assign({}, checkoutSession.guestInformation);

    switch (name) {
      case "name":
        updatedGuestInfo.name = value;
        break;
      case "phone":
        updatedGuestInfo.phone = value;
        break;
      case "email":
        updatedGuestInfo.email = value;
        break;
      default:
        break;
    }

    dispatch(checkoutActions.setCheckoutSessionGuestInfo(updatedGuestInfo));
  }

  return (
    <SectionWrapper index={index} title={t("checkout:GuestInfo.Title")}>
      <div className="input-form">
        <Input inputValue={name} label={t("checkout:GuestInfo.InputName")} errorMessage={nameError ? t(nameError) : ""} overrideParameters={{ type: "text", name: "name" }} inputChanged={handleInputChange} />

        <Input inputValue={phone} label={t("checkout:GuestInfo.InputPhone")} errorMessage={phoneError ? t(phoneError) : ""} overrideParameters={{ type: "tel", name: "phone" }} inputChanged={handleInputChange} />

        <Input inputValue={email} label={t("checkout:GuestInfo.InputEmail")} errorMessage={emailError ? t(emailError) : ""} overrideParameters={{ type: "email", name: "email" }} inputChanged={handleInputChange} />
      </div>
    </SectionWrapper>
  );
}
