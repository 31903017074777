import { get } from "@crunchit/http-client";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_GIFTCARD_URI || `giftcard.${getEnvironment()}crunchy.ninja/api`;

const GiftcardService = {
  validateGiftcard: async (instanceId: string, giftcardCode: string, posSettingInstanceId: string | null) => {
    const params = posSettingInstanceId ? `?posSettingInstanceId=${posSettingInstanceId}` : "";
    return get<number>(`${api}/giftcard/${instanceId}/${giftcardCode}/amount${params}`, getToken());
  },
};

export default GiftcardService;
