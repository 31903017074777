import { IAvailableTimeDto } from "@crunchit/types";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ITimeslotsProps {
  isDelivery: boolean;
  disregardAvailability: boolean;
  availableTimes: IAvailableTimeDto[];
  handleTimeSelection: (updatedTime: string) => void;
}

export default function Timeslots(props: ITimeslotsProps) {
  const { availableTimes, disregardAvailability, isDelivery } = props;
  const { handleTimeSelection } = props;

  const { t } = useTranslation();

  // Generating the list of hours to show in the timepickers
  let timeslotHoursList = [];
  if (availableTimes && availableTimes.length) {
    let startHour = moment.utc(availableTimes[0].time).hour();
    let endHour = moment.utc(availableTimes[availableTimes.length - 1].time).hour();

    timeslotHoursList.push(startHour);
    let currentHour = startHour;
    while (currentHour < endHour) {
      currentHour++;
      timeslotHoursList.push(currentHour);
    }
  }

  const onHourClick = useCallback(
    (hour: number) => {
      const timeslot = availableTimes.find((a) => moment.utc(a.time).hours() === hour);
      let isAvailable = timeslot?.isAvailable;

      if (disregardAvailability) {
        isAvailable = true;
      }

      if (timeslot && isAvailable) {
        handleTimeSelection(timeslot.time);
      }
    },
    [availableTimes, disregardAvailability, handleTimeSelection]
  );

  return (
    <div className="timeslot-view-desktop">
      <div className="timepicker-header">
        <h4>{isDelivery ? t("checkout:DeliveryMethod.DeliveryTime") : t("checkout:DeliveryMethod.PickupTime")}</h4>
      </div>

      <ul className="timepicker-body">
        {timeslotHoursList.map((hour) => (
          <li key={hour} onClick={() => onHourClick(hour)}>
            {`${hour}-${hour + 1}`}
          </li>
        ))}
      </ul>
    </div>
  );
}
