import { ICategoryDto, IProductDto } from "@crunchit/types";
import { formatPrice, isBlockedProduct } from "@crunchit/utilities";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import PrecheckoutProduct from "./PrecheckoutProduct";
import chevronSrc from "assets/img/icons/chevron_black.svg";
import useProducts from "hooks/useProducts";
import useTranslationText from "hooks/useTranslationText";
import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";
import useTracking from "tracking/useTracking";

import "./Precheckout.scss";

interface IPrecheckoutProps {
  precheckoutCategory: ICategoryDto;
  basketItemCount: number;
  basketTotal: number;
  onBackClick: () => void;
  onContinueClick: () => void;
}

export default function Precheckout(props: IPrecheckoutProps) {
  const { precheckoutCategory, basketItemCount, basketTotal, onBackClick, onContinueClick } = props;

  const { appIsInitialized, appSettings } = useAppSelector();
  const { context, isLoading: menuDataIsLoading } = useMenuSelector();
  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();
  const { trackMenuEvent } = useTracking();
  const { blockedProducts, loadProducts, loadWarehouse, products } = useProducts();

  useEffect(() => {
    if (appIsInitialized && appSettings.precheckoutCategory) {
      loadWarehouse();
    }
  }, [appIsInitialized, appSettings.precheckoutCategory, loadWarehouse]);

  useEffect(() => {
    if (precheckoutCategory) {
      loadProducts(precheckoutCategory);
    }
  }, [precheckoutCategory, loadProducts]);

  useEffect(() => {
    if (precheckoutCategory && products.length) {
      trackMenuEvent("CATEGORY_VIEWED", { products, categoryName: precheckoutCategory.name["da-DK"] });
    }
    // eslint-disable-next-line
  }, [products]);

  // Filter out blocked products, remove modifiers
  const precheckoutProducts = useMemo(
    () =>
      products.reduce((acc: IProductDto[], product: IProductDto) => {
        if (isBlockedProduct(product.id, blockedProducts)) {
          return acc;
        }

        const updatedProduct = Object.assign({}, product);
        updatedProduct.modifiers = [];

        acc.push(updatedProduct);

        return acc;
      }, []),
    [products, blockedProducts]
  );

  return (
    <div className="precheckout content">
      <header>
        <button className="back-button" onClick={onBackClick}>
          <span className="icon" style={{ backgroundImage: `url(${chevronSrc})` }}></span>
          <span>{t("cart:GoBack")}</span>
        </button>

        <h1 className="precheckout-title">{getTranslationText(precheckoutCategory.name)}</h1>
      </header>

      <ul className="product-list">
        {!menuDataIsLoading &&
          context &&
          precheckoutProducts.map(function (product: IProductDto) {
            return <PrecheckoutProduct key={product.id} product={product} />;
          })}
      </ul>

      <button className="continue-button button button-colored red" onClick={onContinueClick}>
        <span className="item-count">{basketItemCount}</span>
        <span>{t("cart:Checkout")}</span>
        <span>{formatPrice(basketTotal)} ,-</span>
      </button>
    </div>
  );
}
