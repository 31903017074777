import { IBasketItemDto, IBasketItemChoiceDto, IOrderItemDto, IOrderItemChoiceDto, IProductDto } from "@crunchit/types";
import { IBasketChoiceToAdd } from "models/basket";

export const getEvent = (eventName: string, ecommerce: GTMEcommerceEvent): IGTMEvent => {
  return {
    event: eventName,
    ecommerce: {
      currencyCode: "DKK",
      ...ecommerce,
    },
  };
};

export const mapToGTM = (product: IProductDto | IBasketItemDto | IOrderItemDto): IGTMProduct => {
  if (isBasketItem(product)) {
    return {
      id: `${product.productId}`,
      name: product.name,
      price: `${product.price}`,
    };
  }

  if (isOrderItem(product)) {
    return {
      id: `${product.productId}`,
      name: product.name,
      price: `${product.unitPrice}`,
    };
  }

  // Default - product
  return {
    id: `${product.id}`,
    name: product.name["da-DK"] || "",
    price: `${product.price}`,
  };
};

export const mapChoicesToGtmVariant = (choices: Array<IBasketChoiceToAdd | IBasketItemChoiceDto | IOrderItemChoiceDto>): string => {
  return choices.reduce((previousList: string, choice: IBasketChoiceToAdd | IBasketItemChoiceDto | IOrderItemChoiceDto) => (previousList += `${previousList.length ? "-" : ""}${choice.name}`), "");
};

// Type guards

export const isBasketItem = (basketItem: any): basketItem is IBasketItemDto => {
  return basketItem && "totalPrice" in basketItem;
};
export const isOrderItem = (orderItem: any): orderItem is IOrderItemDto => {
  return orderItem && "unitPrice" in orderItem;
};
