import { useCallback, useEffect } from "react";

export default function useClickAway(elementClassName: string, handleClickAway: () => void) {
  const handleClick = useCallback(
    (event: Event) => {
      const target = event.target as HTMLElement;

      if (target && !target.closest(`.${elementClassName}`)) {
        handleClickAway();
      }
    },
    [elementClassName, handleClickAway]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);
}
