import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import Checkbox from "components/ui/forms/Checkbox";
import "./Terms.scss";

interface ITermsProps {
  checked: boolean;
  updateChecked: (updatedValue: boolean) => void;
}

export default function Terms(props: ITermsProps) {
  const { checked, updateChecked } = props;

  const { appSettings } = useAppSelector();
  const { t } = useTranslation();

  return (
    <div className="terms-checkbox">
      <Checkbox
        checked={checked}
        label={
          <span className="checkbox-label">
            {t("checkout:Payment.FirstTermsText")}{" "}
            <a href={appSettings.terms} target="_blank" rel="noopener noreferrer">
              {t("checkout:Payment.TermsTextLink")}
            </a>{" "}
            {t("checkout:Payment.SecondTermsText")}
          </span>
        }
        handleInputChange={() => updateChecked(!checked)}
      />
    </div>
  );
}
