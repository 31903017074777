import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";

import { useGiftcardValidation } from "./useGiftcardValidation";
import DiscountGiftcardInput from "./DiscountGiftcardInput";

interface IGiftcardProps {
  giftcardInstanceId: string;
}

export default function Giftcard(props: IGiftcardProps) {
  const { giftcardInstanceId } = props;
  const { t } = useTranslation();

  const { giftcardCode, giftcardPaymentAmount, giftcardRemaining, validationIsLoading, errorMessageKey, handleGiftcardValidation, resetGiftcardSession } = useGiftcardValidation(giftcardInstanceId);

  return (
    <div className="giftcard-container">
      <DiscountGiftcardInput validatedCode={giftcardCode} validatedAmount={giftcardPaymentAmount} validationIsLoading={validationIsLoading} errorMessageKey={errorMessageKey} placeholder={t("checkout:Giftcard.Input")} inputNote={giftcardCode ? `${t("checkout:Giftcard.Remaining")}: ${formatPrice(giftcardRemaining)} ${t("common:Currency")}` : ""} handleValidation={handleGiftcardValidation} resetSession={resetGiftcardSession} />
    </div>
  );
}
