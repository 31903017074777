import { ICategoryDto } from "@crunchit/types";
import useTranslationText from "hooks/useTranslationText";
import "./CategoryGrid.scss";

interface ICategoryGridProps {
  categories: ICategoryDto[];
  handleCategoryClick: (categoryId: number) => void;
}

export default function CategoryGrid(props: ICategoryGridProps) {
  const { categories } = props;
  const { handleCategoryClick } = props;

  const { getTranslationText } = useTranslationText();

  return (
    <div className="category-grid">
      {categories.map(function (category, i) {
        const backgroundImage = category.imageUrl ? `url(${category.imageUrl})` : "";
        const description = getTranslationText(category.description);
        const isLastCategory = i + 1 === categories.length;

        return (
          <article key={category.id} className="gridbox" onClick={() => handleCategoryClick(category.id)}>
            <div className="gridbox-image" style={{ backgroundImage }}></div>

            <div className="gridbox-info">
              <h1 className="gridbox-title">{getTranslationText(category.name)}</h1>
              {description.length > 0 && <p className="gridbox-description">{description}</p>}
            </div>

            {!isLastCategory && <div className="gridbox-spacing"></div>}
          </article>
        );
      })}
    </div>
  );
}
