import { formatPrice } from "@crunchit/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./DiscountGiftcardInput.scss";

interface IDiscountGiftcardInputProps {
  validatedCode: string;
  validatedAmount: number;

  validationIsLoading: boolean;
  errorMessageKey: string;

  placeholder: string;
  inputNote?: string; // For additional information, e.g. remaining on giftcard

  handleValidation: (code: string) => void;
  resetSession: () => void;
}

export default function DiscountGiftcardInput(props: IDiscountGiftcardInputProps) {
  const { validatedCode, validatedAmount, validationIsLoading, errorMessageKey, placeholder, inputNote } = props;
  const { handleValidation, resetSession } = props;

  const { t } = useTranslation();

  let [currentInput, setCurrentInput] = useState("");

  async function handleValidateClick() {
    if (currentInput) {
      handleValidation(currentInput);
    }
  }

  function handleResetSessionClick() {
    setCurrentInput("");
    resetSession();
  }

  return (
    <div className="cart-input-container">
      <div className="input-form cart-input-form">
        <div className="input-item">
          <input value={currentInput} type="text" autoComplete="off" placeholder={placeholder} onChange={({ target: { value } }) => setCurrentInput(value)} onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => event.keyCode === 13 && handleValidateClick()} />

          {validationIsLoading && <div className="input-spinner spinner"></div>}
          {!validationIsLoading && validatedAmount > 0 && <p className="input-result">{`- ${formatPrice(validatedAmount)} ${t("common:Currency")}`}</p>}
          {!validationIsLoading && inputNote && <p className="input-note">{inputNote}</p>}

          {errorMessageKey.length > 0 && <div className="input-error">{t(errorMessageKey)}</div>}
        </div>
      </div>

      <button className="button cart-input-button" onClick={validatedCode ? handleResetSessionClick : handleValidateClick}>
        {t(validatedCode ? "checkout:Remove" : "checkout:OK")}
      </button>
    </div>
  );
}
