import { IChoiceDto, IModifierChoiceDto, IProductModifierDto } from "@crunchit/types";
import { getSingleChoicePrice, hasReachedIncludedChoices } from "@crunchit/utilities";
import { useCallback } from "react";
import { ISelectedChoice } from "models/product";
import { getTotalChoiceCount } from "utils/helpers/menu";

export default function useChoices(productModifier: IProductModifierDto, modifierChoices: IModifierChoiceDto[], selectedChoices?: ISelectedChoice[]) {
  // Logic across choices in a modifier
  const totalChoiceCount = selectedChoices ? getTotalChoiceCount(selectedChoices) : 0;

  const includedReached = hasReachedIncludedChoices(totalChoiceCount, productModifier.choicesIncluded, modifierChoices);

  // Single choice logic
  const getChoice = useCallback((choiceId: number, choices: IChoiceDto[]) => choices.find((c: IChoiceDto) => c.id === choiceId), []);
  const getSelectedChoice = useCallback((choiceId: number) => (selectedChoices ? selectedChoices.find((c: ISelectedChoice) => c.id === choiceId) : undefined), [selectedChoices]);

  const getChoicePrice = useCallback(
    (choice: IChoiceDto, modifierChoice: IModifierChoiceDto) => {
      if (includedReached) {
        return getSingleChoicePrice(choice.defaultPrice, modifierChoice.overwritePrice);
      }

      return 0;
    },
    [includedReached]
  );

  return {
    getChoice,
    getSelectedChoice,
    disableAllChoiceIncrease: includedReached,
    getChoicePrice,
  };
}
