import { store } from "store/store";

let environment = "";

if (window.location.href.includes("-ci.azurewebsites.net")) {
  environment = "ci.";
} else if (window.location.href.includes("-staging.azurewebsites.net")) {
  environment = "staging.";
}

const getToken = () => {
  return store.getState().app.token;
};

const getEnvironment = () => {
  return environment;
};

export { getToken, getEnvironment };
