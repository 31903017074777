import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PaymentError from "./PaymentError";
import Terms from "./Terms";
import { usePayment } from "./usePayment";
import { useAppSelector } from "store/app";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

export default function Payment() {
  const { appIsFasttrack } = useAppSelector();
  const { isLoading: checkoutIsLoading } = useCheckoutSelector();
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const { termsAccepted, showRestaurantOfflineError, missingFields, setTermsAccepted, validateCheckout, handlePayment } = usePayment();

  async function handlePaymentButtonClick() {
    const validated = await validateCheckout();

    if (validated) {
      await handlePayment();
    } else {
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }

  const paymentIsDisabled = useMemo(() => {
    if (appIsFasttrack) {
      return checkoutIsLoading;
    }

    return checkoutIsLoading || !termsAccepted;
  }, [appIsFasttrack, checkoutIsLoading, termsAccepted]);

  return (
    <div className="payment-section">
      <div className="finalize-buttons-body">
        {!appIsFasttrack && <Terms checked={termsAccepted} updateChecked={(checked: boolean) => setTermsAccepted(checked)} />}

        <div className="finalize-buttons">
          <button onClick={handlePaymentButtonClick} className="button button-colored green" disabled={paymentIsDisabled}>
            {t("checkout:Payment.PlaceOrder")}
          </button>
        </div>
      </div>

      <PaymentError showRestaurantOfflineError={showRestaurantOfflineError} missingFields={missingFields} />
    </div>
  );
}
