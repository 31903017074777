import { ModuleType } from "@crunchit/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authenticate, initialize, loadDeliveryInstanceSettings } from "./thunks";
import { getCheckoutSettings, navigationSettings, orderSettings, deliverySettings } from "settings";
import { appSettingsFactory } from "./utils/factory";
import { IAppState, ICriticalError } from "models/store";

/* Global app slice */

const initialState: IAppState = {
  appSettings: null,
  token: "",
  productionUnit: null,
  module: null,
  languages: [],
  isLoading: true,
  isDownForMaintenance: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        const { user, module } = action.payload;
        state.token = user.token;
        state.module = module;
      })

      .addCase(initialize.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialize.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(initialize.fulfilled, (state, action) => {
        const { module, productionUnit, frontendSettings, languages, isDownForMaintenance } = action.payload;

        if (module && productionUnit && frontendSettings && languages) {
          state.module = module;
          state.productionUnit = productionUnit;
          state.languages = languages;
          state.isDownForMaintenance = isDownForMaintenance;

          const isFasttrack = module.type === ModuleType.FASTTRACK;
          const checkoutSettings = getCheckoutSettings(isFasttrack);

          // A complete list of settings
          state.appSettings = appSettingsFactory({
            ...navigationSettings,
            ...orderSettings,
            ...deliverySettings,
            ...checkoutSettings,
            ...frontendSettings,
          });
        }

        state.isLoading = false;
      })

      .addCase(loadDeliveryInstanceSettings.fulfilled, (state, action) => {
        if (state.appSettings) {
          state.appSettings.deliveryEnabled = action.payload.deliveryEnabled;
        }
      });
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
