import { ILabelDto } from "@crunchit/types";

export default function Label(props: { label: ILabelDto }) {
  const { label } = props;

  return (
    <div className="label">
      {label.icon !== "" && (
        <p className="label-icon" style={{ color: label.colour }}>
          {label.icon}
        </p>
      )}
    </div>
  );
}
