import { useBasketSelector } from "store/basket";
import icon from "assets/img/icons/basket.svg";
import "./MiniBasket.scss";

interface IMiniBasketProps {
  handleClick: () => void;
}

export default function MiniBasket(props: IMiniBasketProps) {
  const { handleClick } = props;

  const { basketProductCount } = useBasketSelector();

  return (
    <button className="click-allowed minicart" style={{ backgroundImage: `url(${icon})` }} onClick={handleClick}>
      {basketProductCount > 0 && <span className="ordernumber">{basketProductCount}</span>}
    </button>
  );
}
