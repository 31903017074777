import Basket from "components/basket/Basket";
import LanguagePicker from "components/ui/language/LanguagePicker";
import Logo from "components/ui/Logo";
import Smiley from "components/ui/smiley/Smiley";

import "./Topbar.scss";
import { ILayoutOptions } from "models/layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ITopbarProps extends Partial<ILayoutOptions> {
  clickHandlers: {
    sidebar: () => void;
    logo: () => void;
    basket: () => void;
    continue: () => void;
  };
}

export default function Topbar(props: ITopbarProps) {
  const { hideSidebar, hideBasket, clickHandlers } = props;
  const { t } = useTranslation();

  return (
    <nav className="header">
      <div className="inner">
        <div className="header-section mobile-sidebar-trigger">
          {!hideSidebar && (
            <button className="mobile-sidebar-button" onClick={clickHandlers.sidebar}>
              ☰
            </button>
          )}
        </div>

        <div className="header-section">
          <Logo handleClick={clickHandlers.logo} />
        </div>

        <Link className="title" to="/">
          <h1>{t("common:TopbarTitle")}</h1>
        </Link>

        <div className="header-section minicart-section">
          <Smiley />

          <LanguagePicker />

          {!hideBasket && <Basket isMini handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.basket} />}
        </div>
      </div>
    </nav>
  );
}
