import { get } from "@crunchit/http-client";
import { IOpeningHourDto, IProductionUnitDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_PRODUCTIONUNIT_URI || `productionunit.${getEnvironment()}crunchy.ninja/api`;

const ProductionUnitService = {
  getProductionUnitById: async (id: number) => {
    return get<IProductionUnitDto>(`${api}/productionunit/${id}`, getToken());
  },
  getOpeningHoursToday: async (productionUnitId: number) => {
    return get<IOpeningHourDto>(`${api}/productionunit/${productionUnitId}/OpeningHour/v2/today`, getToken());
  },
  getDeliveryHoursToday: async (productionUnitId: number) => {
    return get<IOpeningHourDto>(`${api}/productionunit/${productionUnitId}/DeliveryHour/today`, getToken());
  },
};

export default ProductionUnitService;
