import FasttrackTopbar from "../FasttrackTopbar";
import useHeaderNavigation from "./useHeaderNavigation";
import Sidebar from "../Sidebar";
import "./Header.scss";

export default function FasttrackHeader() {
  const { sidebarIsOpen, sidebarIsClosing, clickHandlers } = useHeaderNavigation();

  return (
    <header className="header-container">
      <FasttrackTopbar clickHandlers={clickHandlers} />
      {sidebarIsOpen && <Sidebar isClosing={sidebarIsClosing} handleCategoryClick={clickHandlers.sidebarCategory} />}
    </header>
  );
}
