import { IBasketItemDto, IOrderDto, IOrderItemDto } from "@crunchit/types";
import track from "./track";
import { getEvent, isBasketItem, isOrderItem, mapToGTM, mapChoicesToGtmVariant } from "./utils/helpers";
import { ICheckoutEventName } from "models/tracking";

const checkoutStep = (data: { step: number; basketItems: IBasketItemDto[] }): IGTMEvent => {
  const { step, basketItems } = data;

  const eventData = {
    checkout: {
      actionField: { step: step },
      products: basketItems.map((basketItem) => {
        return {
          ...mapToGTM(basketItem),
          quantity: basketItem.amount,
          variant: mapChoicesToGtmVariant(basketItem.choices),
        };
      }),
    },
  };

  return getEvent("checkout", eventData);
};

const purchase = (data: { orderItems: IOrderItemDto[]; order: IOrderDto }): IGTMEvent => {
  const { orderItems, order } = data;

  const eventData = {
    purchase: {
      actionField: {
        id: order.externalId || `${order.receipt.number}`,
        revenue: `${order.totalAmount}`,
        tax: `${order.receipt.vatAmount}`,

        ...(order.delivery && { shipping: `${order.delivery.fee}` }),
      },
      products: orderItems.map((orderItem) => {
        return {
          ...mapToGTM(orderItem),
          quantity: orderItem.amount,
          variant: mapChoicesToGtmVariant(orderItem.choices),
        };
      }),
    },
  };

  return getEvent("purchase", eventData);
};

const checkoutEvent = (eventName: ICheckoutEventName, params: { basketItems: IBasketItemDto[] | IOrderItemDto[]; order?: IOrderDto }): void => {
  const { basketItems, order } = params;

  switch (eventName) {
    case "STARTED":
      let basketItemsToTrack = [...basketItems].filter((product: IBasketItemDto | IOrderItemDto) => isBasketItem(product)) as IBasketItemDto[];
      track(checkoutStep({ step: 1, basketItems: basketItemsToTrack }));
      break;

    case "PURCHASE":
      if (order) {
        let orderItemsToTrack = [...basketItems].filter((product: IBasketItemDto | IOrderItemDto) => isOrderItem(product)) as IOrderItemDto[];
        track(purchase({ orderItems: orderItemsToTrack, order }));
      }
      break;

    default:
      break;
  }
};

export default checkoutEvent;
