import SummaryBasket from "components/basket/Basket/SummaryBasket";
import "./SubHeader.scss";

interface ISubHeaderProps {
  name?: string;
  hideBasket?: boolean;
  clickHandlers: {
    sidebar: () => void;
    logo: () => void;
    basket: () => void;
    continue: () => void;
  };
}

export default function SubHeader(props: ISubHeaderProps) {
  const { name, clickHandlers, hideBasket } = props;

  return (
    <div className="subheader">
      <div className="inner">
        {name && <h1>{name}</h1>}
        {!hideBasket && <SummaryBasket handleClick={clickHandlers.basket} />}
      </div>
    </div>
  );
}
