import { ICheckoutDeliveryDto } from "@crunchit/types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCheckoutSelector } from "store/checkout";
import { Input } from "components/ui/forms";
import { isNumber } from "utils/helpers/checkout/validation";
import { useInputValidation } from "hooks/useInputValidation";

interface IAddressInputProps {
  errorInDeliveryAddress: boolean;
  handleDeliveryAddressUpdate: (updatedDeliveryAddress: Partial<ICheckoutDeliveryDto>) => void;
}

export default function AddressInput(props: IAddressInputProps) {
  const { errorInDeliveryAddress, handleDeliveryAddressUpdate } = props;

  const { checkoutSession, isLoading: checkoutIsLoading } = useCheckoutSelector();
  const { delivery } = checkoutSession;
  const { t } = useTranslation();

  // To determine whether to show an error for delivery input - we want to show these errors before customer attempts to pay
  let [inputStarted, setInputStarted] = useState(false);

  let address = (delivery && delivery.address) || "";
  let zipCode = (delivery && delivery.zipCode) || "";

  const { getInputError } = useInputValidation();

  const addressError = useMemo(() => getInputError("deliveryAddress", address, "errors:DeliveryMethod.InputAddress"), [address, getInputError]);
  const zipCodeError = useMemo(() => getInputError("deliveryZipCode", zipCode, "errors:DeliveryMethod.InputZipCode"), [zipCode, getInputError]);

  function handleInputChange(change: { value: string; name: string }) {
    const { value, name } = change;

    if (!inputStarted) {
      setInputStarted(true);
    }

    let updatedDeliveryInfo = delivery ? Object.assign({}, delivery) : { address: "", zipCode: "" };

    switch (name) {
      case "address":
        updatedDeliveryInfo.address = value;
        updatedDeliveryInfo.zipCode = "";
        break;
      case "zipCode":
        const zipCodeInputAllowed = isNumber(value) && value.toString().length < 5;
        if (zipCodeInputAllowed) {
          updatedDeliveryInfo.zipCode = value;
        }
        break;
      default:
        break;
    }

    handleDeliveryAddressUpdate(updatedDeliveryInfo);
  }

  return (
    <div className="delivery-info-input-section">
      <div className={inputStarted ? "input-form show-validation-errors" : "input-form"}>
        <Input inputValue={address} label={t("checkout:DeliveryMethod.InputAddress")} errorMessage={addressError ? t(addressError) : ""} overrideParameters={{ type: "text", name: "address", disabled: checkoutIsLoading }} inputChanged={handleInputChange} />

        <Input inputValue={zipCode} label={t("checkout:DeliveryMethod.InputZipCode")} errorMessage={zipCodeError ? t(zipCodeError) : ""} overrideParameters={{ type: "text", name: "zipCode", disabled: checkoutIsLoading }} inputChanged={handleInputChange} />
      </div>

      {errorInDeliveryAddress && <div className="forced-input-error input-error">{t("errors:DeliveryMethod.DeliveryAddressInvalid")}</div>}
    </div>
  );
}
