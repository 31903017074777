import { IChoiceDto } from "@crunchit/types";
import { useCallback, useMemo } from "react";

import useTranslationText from "hooks/useTranslationText";

interface IChoiceProps {
  choice: IChoiceDto;
  isSelected: boolean;
  price: number;
  handleAddChoice: (choice: IChoiceDto) => void;
  handleRemoveChoice: (choice: IChoiceDto) => void;
}

export default function Choice(props: IChoiceProps) {
  const { choice, isSelected, price } = props;
  const { handleAddChoice, handleRemoveChoice } = props;

  const { getTranslationText } = useTranslationText();

  const descriptionText = useMemo(() => getTranslationText(choice.description), [choice.description, getTranslationText]);
  const isChecked = useMemo(() => isSelected, [isSelected]);

  const onClick = useCallback(() => {
    isChecked ? handleRemoveChoice(choice) : handleAddChoice(choice);
  }, [isChecked, choice, handleAddChoice, handleRemoveChoice]);

  return (
    <li className={`choice ${isChecked ? "active" : ""}`} onClick={onClick}>
      {choice.imageUrl && <div className="choice-image" style={{ backgroundImage: `url(${choice.imageUrl})` }}></div>}

      <div className="choice-input">
        <input type="checkbox" className="checkbox" checked={isChecked} readOnly />

        <div className="choice-details">
          <p className="choice-name">
            <span>{choice ? getTranslationText(choice.name) : ""}</span>

            {descriptionText.length > 0 && (
              <span className="choice-info">
                <span className="choice-info-icon">i</span>
                <span className="choice-info-box">{descriptionText}</span>
              </span>
            )}
          </p>

          {!isChecked && price > 0 && <p className="choice-price">+{price},-</p>}
        </div>
      </div>
    </li>
  );
}
