import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadMenu } from "./thunks";
import { ICriticalError, IMenuState } from "models/store";

/* Menu slice */

const initialState: IMenuState = {
  menuId: "",
  categories: [],
  context: null,
  isLoading: false,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadMenu.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(loadMenu.fulfilled, (state, action) => {
        const { menuId, categories, context } = action.payload;
        state.menuId = menuId; // Caching the menu id
        state.categories = categories;
        state.context = context;
        state.isLoading = false;
      });
  },
});

export const slice = menuSlice.reducer;
export const actions = menuSlice.actions;
