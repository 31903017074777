import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCheckoutSelector } from "store/checkout";
import "./DeliveryMethodPicker.scss";

interface IDeliveryMethodPickerProps {
  deliveryIsDisabled: boolean;
  handleDeliveryMethodUpdate: (updatedIsDelivery: { isDelivery: boolean }) => void;
}

enum DeliveryMethod {
  NONE,
  DELIVERY,
  PICKUP,
}

export default function DeliveryMethodPicker(props: IDeliveryMethodPickerProps) {
  const { deliveryIsDisabled, handleDeliveryMethodUpdate } = props;

  const { checkoutSession } = useCheckoutSelector();
  const { t } = useTranslation();

  const [deliveryMethod, setDeliveryMethod] = useState(checkoutSession.isDelivery ? DeliveryMethod.DELIVERY : DeliveryMethod.PICKUP);

  const onDeliveryMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const method = parseInt(event.currentTarget.value);
      setDeliveryMethod(method);
      
      const isDelivery = method === DeliveryMethod.DELIVERY;
      handleDeliveryMethodUpdate({ isDelivery });
    } catch (e) {
      return;
    }
  };

  return (
    <div className="delivery-picker">
      <label className="delivery-picker-option" htmlFor="pickup">
        <input type="radio" id="pickup" value={DeliveryMethod.PICKUP} checked={deliveryMethod === DeliveryMethod.PICKUP} onChange={onDeliveryMethodChange} />
        <span>{t("checkout:DeliveryMethod.PickupTitle")}</span>
      </label>

      <label className="delivery-picker-option" htmlFor="delivery">
        <input type="radio" id="delivery" value={DeliveryMethod.DELIVERY} checked={deliveryMethod === DeliveryMethod.DELIVERY} onChange={onDeliveryMethodChange} />
        <span>{t("checkout:DeliveryMethod.DeliveryTitle")}</span>

        {deliveryIsDisabled && <p className="delivery-disabled-text">{t("errors:DeliveryMethod.NotAllowedGiftcard")}</p>}
      </label>
    </div>
  );
}
