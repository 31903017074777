import { IBlockedChoiceDto, IChoiceDto, IContextDto, IModifierDto, IProductDto, IProductModifierDto } from "@crunchit/types";
import { filterModifiersForSoldOutChoices } from "@crunchit/utilities";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import useTranslationText from "hooks/useTranslationText";
import { IBasketChoiceToAdd } from "models/basket";
import { ISelectedChoice } from "models/product";
import { basketThunks } from "store/basket";
import { useCustomDispatch } from "store/useStore";
import useTracking from "tracking/useTracking";
import { getTotalChoiceCount, getTotalChoicePriceForModifier } from "utils/helpers/menu";

export default function useModifiers(product: IProductDto, context: IContextDto) {
  const dispatch = useCustomDispatch();

  const { trackCartEvent } = useTracking();
  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const [selectedChoices, setSelectedChoices] = useState<Map<number, ISelectedChoice[]>>(new Map());

  const getProductModifier = useCallback((modifierId: number) => product.modifiers.find((m: IProductModifierDto) => m.modifierId === modifierId), [product]);

  const getModifierTitle = useCallback(
    (modifier: IModifierDto, productModifier?: IProductModifierDto) => {
      const translatedModifierName = getTranslationText(modifier.name);

      if (!productModifier) {
        return translatedModifierName;
      }

      const { minChoices, maxChoices } = productModifier;

      if (minChoices > 0 && maxChoices > minChoices) {
        return `${t("products:ChooseAtLeast")} ${minChoices} ${t("products:AndUpTo")} ${maxChoices} ${translatedModifierName}`;
      }

      if (minChoices === 0 && maxChoices > minChoices) {
        return `${t("products:ChooseUpTo")} ${maxChoices} ${translatedModifierName}`;
      }

      if (maxChoices === 0 && minChoices > maxChoices) {
        return `${t("products:ChooseAtLeast")} ${minChoices} ${translatedModifierName}`;
      }

      if (maxChoices > 0 && maxChoices === minChoices) {
        return `${t("products:Choose")} ${maxChoices} ${translatedModifierName}`;
      }

      return `${t("products:Choose")} ${translatedModifierName}`;
    },
    [getTranslationText, t]
  );

  const getModifiers = useCallback(
    (blockedChoices: IBlockedChoiceDto[]) => {
      const productModifierIds = product.modifiers.map((mod) => mod.modifierId); // This list has sortOrder and is already sorted so we use that order
      const blockedChoiceIds = blockedChoices.map((b) => b.choiceId);
      const filteredModifiers = filterModifiersForSoldOutChoices(context.modifiers, productModifierIds, blockedChoiceIds);

      // Add product modifier and modifier title
      const mappedModifiers = filteredModifiers.map((modifier) => {
        const productModifier = getProductModifier(modifier.id);
        const title = getModifierTitle(modifier, productModifier);
        return { ...modifier, productModifier, title };
      });

      return mappedModifiers;
    },
    [product, context, getProductModifier, getModifierTitle]
  );

  const addChoiceToModifier = useCallback(
    (choice: IChoiceDto, modifier: IModifierDto, productModifier: IProductModifierDto) => {
      const newSelectedModifierChoices = selectedChoices.get(modifier.id) || [];
      newSelectedModifierChoices.push({ id: choice.id, name: choice.name["da-DK"] as string });

      if (productModifier.maxChoices > 0) {
        while (newSelectedModifierChoices.length - productModifier.maxChoices > 0) {
          newSelectedModifierChoices.shift();
        }
      }

      const newSelectedChoices = new Map(selectedChoices);
      newSelectedChoices.set(modifier.id, newSelectedModifierChoices);

      setSelectedChoices(newSelectedChoices);
    },
    [selectedChoices]
  );

  const removeChoiceFromModifier = useCallback(
    (choice: IChoiceDto, modifier: IModifierDto) => {
      const currentlySelectedModifierChoices = selectedChoices.get(modifier.id) || [];
      const newSelectedModifierChoices = currentlySelectedModifierChoices.filter((selectedChoice) => selectedChoice.id !== choice.id);
      
      const newSelectedChoices = new Map(selectedChoices);
      newSelectedChoices.set(modifier.id, newSelectedModifierChoices);
      setSelectedChoices(newSelectedChoices);
    },
    [selectedChoices]
  );

  const getTotalPriceForModifiers = useCallback(
    (modifiers: IModifierDto[], servingsCount: number) => {
      let price = product.price * servingsCount;

      selectedChoices.forEach((choices, modifierId) => {
        const modifier = modifiers.find((m) => m.id === modifierId);
        const productModifier = getProductModifier(modifierId);

        if (modifier && productModifier) {
          const choicePrice = getTotalChoicePriceForModifier(1, modifier, productModifier?.choicesIncluded, context.choices, choices);
          price += servingsCount * choicePrice;
        }
      });

      return price;
    },
    [product, context, selectedChoices, getProductModifier]
  );

  const minChoicesReached = useCallback(
    (modifier: IModifierDto) => {
      const productModifier = getProductModifier(modifier.id);

      if (!productModifier || !productModifier.minChoices) {
        return true;
      }

      const selected = selectedChoices.get(modifier.id) || [];
      const choiceCount = getTotalChoiceCount(selected);

      return choiceCount >= productModifier.minChoices;
    },
    [selectedChoices, getProductModifier]
  );

  const includedChoicesReached = useCallback(
    (modifier: IModifierDto) => {
      const productModifier = getProductModifier(modifier.id);

      if (!productModifier || !productModifier.minChoices) {
        return true;
      }

      const selected = selectedChoices.get(modifier.id) || [];
      const choiceCount = getTotalChoiceCount(selected);

      return choiceCount >= productModifier.choicesIncluded;
    },
    [selectedChoices, getProductModifier]
  );

  const finalize = useCallback(
    async (servingsCount: number) => {
      let choicesToAdd: IBasketChoiceToAdd[] = [];

      const addChoices = (modifierId: number, selectedChoiceList: ISelectedChoice[]) => {
        const mappedChoices = selectedChoiceList.map<IBasketChoiceToAdd>((sChoice: ISelectedChoice) => {
          return { ...sChoice, choiceId: sChoice.id, modifierId, amount: 1 };
        });

        choicesToAdd = choicesToAdd.concat(mappedChoices.filter((c) => c.amount > 0));
      };

      selectedChoices.forEach((choices, modifierId) => addChoices(modifierId, choices));
      await dispatch(basketThunks.addBasketItem({ product, amount: servingsCount, choices: choicesToAdd }));

      trackCartEvent("ADD", { product, choices: choicesToAdd });

      setSelectedChoices(new Map());
    },
    [selectedChoices, dispatch, product, trackCartEvent]
  );

  return {
    getModifiers,
    selectedChoices,
    addChoiceToModifier,
    removeChoiceFromModifier,
    minChoicesReached,
    includedChoicesReached,
    getTotalPriceForModifiers,
    finalize,
  };
}
