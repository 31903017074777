import Basket from "components/basket/Basket";
import SummaryBasket from "components/basket/Basket/SummaryBasket";
import Logo from "components/ui/Logo";

import "./Topbar.scss";
import { ILayoutOptions } from "models/layout";

interface ITopbarProps extends Partial<ILayoutOptions> {
  clickHandlers: {
    sidebar: () => void;
    logo: () => void;
    basket: () => void;
    continue: () => void;
  };
}

export default function FasttrackTopbar(props: ITopbarProps) {
  const { clickHandlers } = props;

  return (
    <nav className="header">
      <div className="inner">
        <div className="header-section mobile-sidebar-trigger">
          <button className="mobile-sidebar-button" onClick={clickHandlers.sidebar}>
            ☰
          </button>
        </div>

        <div className="header-section">
          <Logo handleClick={clickHandlers.logo} />
        </div>

        <div className="header-section cart-section">
          <Basket isMini handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.continue} />
          <SummaryBasket handleClick={clickHandlers.continue} />
        </div>
      </div>
    </nav>
  );
}
