import { IBasketItemDto, IProductDto } from "@crunchit/types";
import track from "./track";
import { IBasketChoiceToAdd } from "models/basket";
import { ICartEventName } from "models/tracking";
import { getEvent, isBasketItem, mapToGTM, mapChoicesToGtmVariant } from "./utils/helpers";

interface ICartEventParams {
  product: IProductDto | IBasketItemDto;
  choices?: IBasketChoiceToAdd[];
}

const add = (params: ICartEventParams): IGTMEvent => {
  const { product, choices } = params;

  let productChoices = choices;
  if ("choices" in product) {
    productChoices = product.choices;
  }

  const eventData = {
    add: {
      products: [
        {
          ...mapToGTM(product),
          quantity: 1,
          ...(productChoices && { variant: mapChoicesToGtmVariant(productChoices) }),
        },
      ],
    },
  };

  return getEvent("addToCart", eventData);
};

const remove = (data: { basketItem: IBasketItemDto }): IGTMEvent => {
  const { basketItem } = data;

  const eventData = {
    remove: {
      products: [
        {
          ...mapToGTM(basketItem),
          quantity: basketItem.amount,
          variant: mapChoicesToGtmVariant(basketItem.choices),
        },
      ],
    },
  };

  return getEvent("removeFromCart", eventData);
};

const cartEvent = (eventName: ICartEventName, params: ICartEventParams): void => {
  const { product } = params;

  switch (eventName) {
    case "ADD":
      track(add(params));
      break;

    case "REMOVE":
      if (isBasketItem(product)) {
        track(remove({ basketItem: product }));
      }
      break;

    default:
      break;
  }
};

export default cartEvent;
