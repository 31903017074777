import { get } from "@crunchit/http-client";
import { IMainenanceModeDto } from "@crunchit/types";
import { getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_STORAGE_URI || `${getEnvironment()}crunchorderfrontend.blob.core.windows.net`;

const StorageService = {
  getMaintenanceMode: async () => {
    return get<IMainenanceModeDto>(`${api}/universal/maintenanceMode.json`);
  },
};

export default StorageService;
