import { ITranslation } from "@crunchit/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Counter from "components/ui/Counter";

interface IModifierViewProps {
  productName: ITranslation;
  quantity: number;
  minQuantity: number;
  handleChange: (updatedCount: number, increased: boolean) => void;
  index?: number;
}

export default function QuantityModifier(props: IModifierViewProps) {
  const { quantity, minQuantity, handleChange } = props;

  const { t } = useTranslation();

  const disableDecrease = useMemo(() => quantity === minQuantity, [quantity, minQuantity]);

  return (
    <article className="modifier quantity-modifier collapsible-section expanded">
      <h1 className="modifier-title">{t("products:HowMany")}</h1>

      <div className="modifier-content">
        <Counter count={quantity} disableDecrease={disableDecrease} handleChange={handleChange} />
      </div>
    </article>
  );
}
