import { IAppSettings, IFrontendSettingsDto, IModuleDto } from "@crunchit/types";
import { defaultAppSettings, defaultFrontendSettings, defaultModule } from "./defaults";

export const moduleFactory = (module: Partial<IModuleDto> | null = {}): IModuleDto => {
  return {
    ...defaultModule,
    ...module,
  };
};

export const frontendSettingsFactory = (frontendSettings: Partial<IFrontendSettingsDto> | null = {}): IFrontendSettingsDto => {
  return {
    ...defaultFrontendSettings,
    ...frontendSettings,
  };
};

export const appSettingsFactory = (appSettings: Partial<IAppSettings> | null = {}): IAppSettings => {
  return {
    ...defaultAppSettings,
    ...appSettings,
  };
};
