import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import PlaceholderBanner from "components/banner/PlaceholderBanner";
import Header from "components/navigation/Header/Header";
import FasttrackHeader from "components/navigation/Header/FasttrackHeader";
import Spinner from "components/ui/Spinner";
import useAppState from "hooks/useAppState";
import useAppRedirects from "hooks/useAppRedirects";
import { IViewProps } from "models/layout";
import { useAppSelector } from "store/app";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, layoutOptions, children } = props;
  const { isStatusPage = false, hideBasket = false, hideSidebar = false, fullWidth = false } = layoutOptions || {};

  const { isError, isLoading, loadingMessageKey } = useAppState();
  const { appIsFasttrack } = useAppSelector();
  const { pathname } = useLocation();

  const redirect = useNavigate();
  const redirectPath = useAppRedirects({ isError, isLoading });

  const [showOverlay, setshowOverlay] = useState(false);

  const showPlaceholderBanner = useMemo(() => pathname === "/" || pathname.startsWith("/menu"), [pathname]);

  const HeaderComponent = useMemo(() => {
    return appIsFasttrack ? FasttrackHeader : Header;
  }, [appIsFasttrack]);

  useEffect(() => {
    if (redirectPath) {
      redirect(redirectPath);
    }
  }, [redirectPath, redirect]);

  return (
    <div className={`page-view ${isStatusPage ? "status-page" : ""} ${pageKey}-page ${appIsFasttrack ? "fasttrack" : ""}`}>
      <HeaderComponent showOverlay={showOverlay} hideBasket={hideBasket} hideSidebar={hideSidebar} onBasketIsOpenChanged={setshowOverlay} />

      {showPlaceholderBanner && <PlaceholderBanner />}
      {showOverlay && <div id="page-overlay"></div>}

      <div className={`page-view-content ${fullWidth ? "" : "inner"}`.trim()}>
        {/* Content from each component */}
        {children}
      </div>

      {isLoading && <Spinner loadingMessageKey={loadingMessageKey} />}
    </div>
  );
}
