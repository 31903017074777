import { IBlockedChoiceDto, IContextDto, ILabelDto, IProductDto } from "@crunchit/types";

import ProductDetails from "./ProductDetails";
import Modifiers from "components/menu/Modifiers";

import "./Product.scss";

interface IProductViewProps {
  product: IProductDto;
  context: IContextDto;
  isBlockedProduct: boolean;
  blockedChoices: IBlockedChoiceDto[];
  labels?: ILabelDto[];
}

export default function Product(props: IProductViewProps) {
  const { product, context, isBlockedProduct, blockedChoices, labels } = props;

  const hasModifiers = product.modifiers && product.modifiers.length > 0;

  return (
    <article className={isBlockedProduct ? "product blocked" : "product"}>
      <section className="product-content">
        {product.imageUrl && <div className="product-image" style={{ backgroundImage: "url(" + product.imageUrl + ")" }}></div>}
        <ProductDetails product={product} labels={labels} />
      </section>

      {hasModifiers && <Modifiers product={product} context={context} blockedChoices={blockedChoices} />}
    </article>
  );
}
