import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "normalize.css/normalize.css";

import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "store/store";
import "assets/styles/index.scss";
import "i18n";

import App from "./App";
import ErrorBoundary from "./views/ErrorBoundary";
import Spinner from "./components/ui/Spinner";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  </Provider>
);
