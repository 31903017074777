import { IOpeningHourDto } from "@crunchit/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionUnitService from "services/ProductionUnitService";

interface IOpeningHoursProps {
  productionUnitId: number;
  isDelivery: boolean;
}

function Hours(title: string, hours: string) {
  return (
    <span>
      {title.toLowerCase()}: <span className="opening-hours-hour-interval">{hours}</span>
    </span>
  );
}

export default function OpeningHours(props: IOpeningHoursProps) {
  const { productionUnitId, isDelivery } = props;

  const [pickupHoursString, setPickupHoursString] = useState<string>("");
  const [deliveryHoursString, setDeliveryHoursString] = useState<string>("");

  const { t } = useTranslation();

  const hasHourData = useMemo(() => {
    return isDelivery ? deliveryHoursString : pickupHoursString;
  }, [isDelivery, deliveryHoursString, pickupHoursString]);

  const trimSecondsFromHour = (hour: string) => hour.substring(0, hour.length - 3);

  useEffect(() => {
    function hourIntervalString(hours: IOpeningHourDto) {
      return `${trimSecondsFromHour(hours.open)} - ${trimSecondsFromHour(hours.close)}`;
    }

    async function setPickupHours(productionUnitId: number) {
      const pickupHoursResponse = await ProductionUnitService.getOpeningHoursToday(productionUnitId);

      if (pickupHoursResponse.isSuccess()) {
        const pickupHours = pickupHoursResponse.data;

        if (pickupHours) {
          setPickupHoursString(hourIntervalString(pickupHours));
        }
      }
    }

    async function setDeliveryHours(productionUnitId: number) {
      const deliveryHoursResponse = await ProductionUnitService.getDeliveryHoursToday(productionUnitId);

      if (deliveryHoursResponse.isSuccess()) {
        const deliveryHours = deliveryHoursResponse.data;

        if (deliveryHours) {
          setDeliveryHoursString(hourIntervalString(deliveryHours));
        }
      }
    }

    if (isDelivery && !deliveryHoursString) {
      setDeliveryHours(productionUnitId);
    } else if (!pickupHoursString) {
      setPickupHours(productionUnitId);
    }
  }, [productionUnitId, isDelivery, deliveryHoursString, pickupHoursString]);

  if (!hasHourData) {
    return <></>;
  }

  return (
    <p className="opening-hours">
      {t("checkout:DeliveryMethod.OpeningHours")}, {isDelivery ? Hours(t("checkout:DeliveryMethod.DeliveryTitle"), deliveryHoursString) : Hours(t("checkout:DeliveryMethod.PickupTitle"), pickupHoursString)}
    </p>
  );
}
