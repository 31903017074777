import { ISessionChoice } from "@crunchit/types";
import { useTranslation } from "react-i18next";

export default function ChoiceList({ choices }: { choices: ISessionChoice[] }) {
  const { t } = useTranslation();

  return (
    <ul className="product-choices">
      {choices.map((choice) => {
        return (
          <li className="product-choice" key={choice.id}>
            <span className="choice-name">{choice.name}</span>
            <span className="choice-price">
              {choice.amount * choice.price} {t("common:Currency")}
            </span>
          </li>
        );
      })}
    </ul>
  );
}
