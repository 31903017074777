import { useTranslation } from "react-i18next";
import "./Counter.scss";

interface ICounterProps {
  count: number;
  isDisabled?: boolean; // Disabling the whole counter, e.g. when data is loading
  disableIncrease?: boolean;
  disableDecrease?: boolean;
  handleChange: (updatedCount: number, increased: boolean) => void;
}

export default function Counter(props: ICounterProps) {
  const { count, isDisabled, disableIncrease, disableDecrease, handleChange } = props;
  const { t } = useTranslation();

  function handlePlusClick() {
    if (!disableIncrease) {
      let updatedCount = count + 1;
      handleChange(updatedCount, true);
    }
  }

  function handleMinusClick() {
    if (!disableDecrease) {
      let updatedCount = count - 1;
      handleChange(updatedCount, false);
    }
  }

  return (
    <div className="counter">
      <button className="count-btn minus" onClick={handleMinusClick} disabled={isDisabled || disableDecrease || count === 0}>
        -
      </button>

      <p className="counter-text">
        <span className="counter-text-number-container">
          <span className="counter-text-number">{count}</span> <span className="counter-text-extra">{t("common:Unit")}</span>
        </span>
      </p>

      <button className="count-btn plus" onClick={handlePlusClick} disabled={isDisabled || disableIncrease}>
        +
      </button>
    </div>
  );
}
