import { useTranslation } from "react-i18next";

import "./Spinner.scss";

export default function Spinner(props: { loadingMessageKey?: string }) {
  const { loadingMessageKey } = props;
  const { t } = useTranslation();

  return (
    <div className="spinner-section">
      <div className="spinner-background"></div>
      <div className="spinner-container">
        <div className="fullpage-spinner"></div>
        {loadingMessageKey !== undefined && loadingMessageKey !== "" && <p className="information-text">{t(loadingMessageKey)}</p>}
      </div>
    </div>
  );
}
