import { IBlockedChoiceDto, IBlockedProductDto, ICategoryDto, ILabelDto, IProductDto } from "@crunchit/types";
import { isBlockedProduct } from "@crunchit/utilities";
import { useCallback, useState } from "react";
import ProductService from "services/ProductService";
import WarehouseService from "services/WarehouseService";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

export default function useProducts() {
  const { module } = useAppSelector();
  const { context } = useMenuSelector();

  let [blockedProducts, setBlockedProducts] = useState<IBlockedProductDto[]>([]);
  let [blockedChoices, setBlockedChoices] = useState<IBlockedChoiceDto[]>([]);

  let [products, setProducts] = useState<IProductDto[]>([]);
  let [labels, setLabels] = useState<ILabelDto[]>([]);

  const getAvailableProductIds = useCallback(
    (productIds: number[]) => {
      return productIds.filter((id) => !isBlockedProduct(id, blockedProducts));
    },
    [blockedProducts]
  );

  const loadWarehouse = useCallback(async () => {
    const [blockedProductsResponse, blockedChoicesResponse] = await Promise.all([WarehouseService.getBlockedProducts(module.productionUnitId), WarehouseService.getBlockedChoices(module.productionUnitId)]);

    if (blockedProductsResponse.isSuccess()) {
      setBlockedProducts(blockedProductsResponse.data);
    }

    if (blockedChoicesResponse.isSuccess()) {
      setBlockedChoices(blockedChoicesResponse.data);
    }
  }, [module.productionUnitId]);

  const loadLabels = useCallback(async () => {
    if (module.productInstanceId) {
      try {
        const labelsResponse = await ProductService.getLabels(module.productInstanceId);

        if (labelsResponse.isSuccess()) {
          setLabels(labelsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [module.productInstanceId]);

  const loadProducts = useCallback(
    (category: ICategoryDto) => {
      if (!context) {
        return;
      }

      const categoryProducts = category.products;
      const products = categoryProducts.map((c) => context.products.find((p) => p.id === c.productId));
      const filteredProducts = products.filter((p) => p !== undefined && p.price > 0) as IProductDto[];

      setProducts(filteredProducts);
    },
    [context]
  );

  return {
    blockedProducts,
    blockedChoices,

    labels,
    products,

    loadWarehouse,
    loadLabels,
    loadProducts,

    getAvailableProductIds,
  };
}
