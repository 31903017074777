const track = (eventData: IGTMEvent): void => {
  window.dataLayer = window.dataLayer || [];

  // Clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null });

  if (process.env.NODE_ENV === "production") {
    // Push to datalayer
    window.dataLayer.push(eventData);
  }
};

export default track;
