import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import maintenanceIcon from "assets/img/icons/maintenance.svg";
import Page from "components/ui/page/Page";
import "./Maintenance.scss";

export default function Maintenance() {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <>
      <img loading="lazy" className="maintenance-icon" src={maintenanceIcon} alt="maintenance" />

      <Page title={t("pagetitles:MaintenancePage.Title")} ignoreFasttrack>
        <div className="content-body">
          <p className="status-page-text">{t("errors:Maintenance")}</p>
          <button className="button button-colored red" onClick={() => redirect("/")}>
            {t("errors:Actions.TryAgain")}
          </button>
        </div>
      </Page>
    </>
  );
}
