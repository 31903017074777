import { IBlockedChoiceDto, IContextDto, IProductDto } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useModifiers from "./useModifiers";
import QuantityModifier from "./QuantityModifier";
import Modifier from "./Modifier";
import useTracking from "tracking/useTracking";

import "./Modifiers.scss";

interface IModifierListProps {
  product: IProductDto;
  context: IContextDto;
  blockedChoices: IBlockedChoiceDto[];
}

export default function Modifiers(props: IModifierListProps) {
  // Wrapper for all productModifiers, to keep track of choices, max and min, etc.
  const { product, context, blockedChoices } = props;
  const { t } = useTranslation();
  const { trackMenuEvent } = useTracking();

  const minServingsCount = 20;

  const [servingsCount, setServingsCount] = useState(minServingsCount);

  const { getModifiers, selectedChoices, addChoiceToModifier, removeChoiceFromModifier, minChoicesReached, includedChoicesReached, getTotalPriceForModifiers, finalize } = useModifiers(product, context);

  const modifiers = useMemo(() => getModifiers(blockedChoices), [getModifiers, blockedChoices]);
  const totalPrice = useMemo(() => getTotalPriceForModifiers(modifiers, servingsCount), [getTotalPriceForModifiers, modifiers, servingsCount]);

  const modifiersMissingIncludedChoices = useMemo(() => modifiers.filter((modifier) => !includedChoicesReached(modifier)), [modifiers, includedChoicesReached]);
  const modifiersMissingChoices = useMemo(() => modifiers.filter((modifier) => !minChoicesReached(modifier)), [modifiers, minChoicesReached]);

  const modifiersWithWarning = useMemo(() => Array.from(new Set(modifiersMissingChoices.concat(modifiersMissingIncludedChoices))), [modifiersMissingIncludedChoices, modifiersMissingChoices]);

  const showWarning = useMemo(() => modifiersMissingIncludedChoices.length > 0 || modifiersMissingChoices.length > 0, [modifiersMissingIncludedChoices, modifiersMissingChoices]);
  const disableFinalize = useMemo(() => modifiersMissingChoices.length > 0, [modifiersMissingChoices]);

  const handleServingsCountUpdate = (updatedCount: number) => setServingsCount(updatedCount);

  const handleFinalizeClick = async () => {
    window.scrollTo(0, 0);
    await finalize(servingsCount);
  };

  useEffect(() => {
    trackMenuEvent("MODIFIER_EXPANDED", { products: [product] });
  }, [product, trackMenuEvent]);

  return (
    <section className="product-modifier-container">
      <QuantityModifier productName={product.name} quantity={servingsCount} minQuantity={minServingsCount} handleChange={handleServingsCountUpdate} />

      {modifiers.map(function (modifier, index) {
        const { id, productModifier, title } = modifier;
        return !productModifier ? null : <Modifier key={index} title={title} modifier={modifier} productModifier={productModifier} choices={context.choices} selectedChoices={selectedChoices.get(id)} handleAddChoice={(choice) => addChoiceToModifier(choice, modifier, productModifier)} handleRemoveChoice={(choice) => removeChoiceFromModifier(choice, modifier)} />;
      })}

      <div className="product-modifier-footer">
        {showWarning && (
          <div className="missing-choices-warning">
            <p>{t("errors:Products.ModifierChoicesMissing")}:</p>

            <ul>
              {modifiersWithWarning.map((modifierWithWarning) => {
                return <li key={modifierWithWarning.id}>{modifierWithWarning.title}</li>;
              })}
            </ul>
          </div>
        )}

        <button className="button button-colored red" disabled={disableFinalize} onClick={() => handleFinalizeClick()}>
          <span>{t("products:AddToCart")}</span>
          <span>{formatPrice(totalPrice ? totalPrice : 0)} ,-</span>
        </button>
      </div>
    </section>
  );
}
