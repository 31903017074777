import { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { IBlockedProductDto, ICategoryDto } from "@crunchit/types";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import Product from "components/menu/Product";

import useTranslationText from "hooks/useTranslationText";
import useProducts from "hooks/useProducts";
import useTracking from "tracking/useTracking";
import Sidebar from "components/navigation/Sidebar";
import Page from "components/ui/page/Page";

import "./Category.scss";

export default function Category() {
  const { appIsInitialized } = useAppSelector();
  const { context, categories, isLoading: menuDataIsLoading } = useMenuSelector();
  const { categoryId } = useParams();
  const { getTranslationText } = useTranslationText();

  let { blockedProducts, blockedChoices, labels, products, loadWarehouse, loadLabels, loadProducts } = useProducts();

  const { trackMenuEvent } = useTracking();

  const category = useMemo(() => (categoryId ? categories.find((c: ICategoryDto) => c.id === Number(categoryId)) : undefined), [categoryId, categories]);
  const product = useMemo(() => products[0], [products]);

  useEffect(() => {
    if (appIsInitialized) {
      loadWarehouse();
      loadLabels();
    }
  }, [appIsInitialized, category, loadLabels, loadWarehouse]);

  useEffect(() => {
    if (category) {
      loadProducts(category);
    }
  }, [category, loadProducts]);

  useEffect(() => {
    if (products.length) {
      trackMenuEvent("CATEGORY_VIEWED", { products: products });
    }
    // eslint-disable-next-line
  }, [products]);

  if (!category) {
    return <></>;
  }

  const title = getTranslationText(category.name);

  return (
    <Page title={title}>
      <div className="content-body sidebar-body">
        <Sidebar />

        <>{!menuDataIsLoading && context !== null && product !== undefined && <Product key={product.id} product={product} context={context} labels={labels} isBlockedProduct={blockedProducts.find((b: IBlockedProductDto) => b.productId === product.id) !== undefined} blockedChoices={blockedChoices} />}</>
      </div>
    </Page>
  );
}
