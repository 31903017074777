import { get } from "@crunchit/http-client";
import { IUserDto } from "@crunchit/types";
import { getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_AUTH_URI || `auth.${getEnvironment()}crunchy.ninja/api`;

const AuthService = {
  authenticate: async (productionUnitId: number) => {
    return get<IUserDto>(`${api}/token/${productionUnitId}`);
  },
};

export default AuthService;
