import { ISessionChoice, ISessionItem } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ChoiceList from "./ChoiceList";
import { useMenuSelector } from "store/menu";
import { getModifierIds, getSummarizedChoices, sortModifierIds } from "utils/helpers/orderlist";

import "./OrderListItem.scss";

interface IOrderListItemProps {
  product: ISessionItem;

  showChoices?: boolean;
  hideEditOptions?: boolean; // Items cannot be edited at all, so hide the options
  disableEditOptions: boolean; // Temporarily not allowed to edit the items

  itemCountChanged: (productId: number, newCount: number) => void;
}

export default function OrderListItem(props: IOrderListItemProps) {
  const { product } = props;
  const { itemCountChanged } = props;

  const { context } = useMenuSelector();
  const { t } = useTranslation();

  const productEditIsDisabled = product.editingIsDisabled; // This particular product cannot be edited

  function handleItemRemove() {
    itemCountChanged(product.productId, 0);
  }

  const showChoices = useMemo(() => props.showChoices && product.choices.length > 0, [props.showChoices, product.choices]);

  const modifierIds = useMemo(() => {
    return showChoices ? sortModifierIds(getModifierIds(product.choices), product.productId, context) : [];
  }, [showChoices, product.productId, product.choices, context]);

  const getChoicesForModifierId = (modifierId: number, choices: ISessionChoice[]) => {
    return getSummarizedChoices(choices.filter((choice) => choice.modifierId === modifierId));
  };

  return (
    <div className={`orderlist-item ${productEditIsDisabled ? "disabled" : ""} ${showChoices ? "with-choices" : ""}`}>
      <div className="product">
        <span className="product-amount">
          {product.amount} {t("common:Unit")}
        </span>

        <span className="product-name">{product.name}</span>

        <span className="product-price">
          {formatPrice(product.totalPrice)} {t("common:Currency")}
        </span>

        {!productEditIsDisabled && (
          <button className="product-delete" onClick={handleItemRemove}>
            {t("cart:Remove")}
          </button>
        )}
      </div>

      {showChoices && (
        <ul className="product-modifiers">
          {modifierIds.map((modifierId) => (
            <li className="product-modifier" key={modifierId}>
              <ChoiceList choices={getChoicesForModifierId(modifierId, product.choices)} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
