import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import CategoryGrid from "components/ui/CategoryGrid";
import Page from "components/ui/page/Page";

export default function Home() {
  const { t } = useTranslation();
  const { appSettings } = useAppSelector();
  const { categories } = useMenuSelector();
  const redirect = useNavigate();

  const handleCategoryClick = (categoryId: number) => redirect(`/menu/${categoryId}`);

  if (appSettings.showClosedFrontpageMessage) {
    return (
      <div className="content-body closed-frontpage-message">
        <Page title={t("pagetitles:ClosedFrontpage.Title")} subtitle={t("pagetitles:ClosedFrontpage.Subtitle")} ignoreFasttrack />
      </div>
    );
  }

  return (
    <div className="content-body">
      <Page title={t("pagetitles:Frontpage.Title")}>
        <CategoryGrid categories={categories} handleCategoryClick={handleCategoryClick} />
      </Page>
    </div>
  );
}
