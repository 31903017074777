import { IPaymentDto, PaymentType } from "@crunchit/types";
import { useCallback } from "react";

import { useBasketSelector } from "store/basket";
import { useCustomSelector } from "store/useStore";

import { checkoutFactory, checkoutInstanceSettingsFactory, checkoutSessionFactory } from "./utils/factory";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.checkout);

  const { basketProductTotal } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutInstanceSettings, checkoutSession } = state;

  const delivery = checkout?.delivery;
  const deliveryFee = delivery?.deliveryFee || 0;

  const discount = checkoutSession?.discount;
  const giftcard = checkoutSession?.giftcard;

  let getCheckoutPayments = useCallback(() => {
    let sessionPayments: Partial<IPaymentDto>[] = [];

    if (giftcard) {
      const { giftcardCode = "", amount = 0 } = giftcard;

      if (giftcardCode && amount > 0) {
        const discountAmount = (discount && discount.amount) || 0;

        const basketTotalUnpaid = basketProductTotal - discountAmount + deliveryFee;
        const giftcardPaymentAmount = amount > basketTotalUnpaid ? basketTotalUnpaid : amount;

        sessionPayments = [{ paymentId: giftcardCode, amount: giftcardPaymentAmount, type: PaymentType.GIFTCARD }];
      }
    }
    return sessionPayments;
  }, [deliveryFee, giftcard, discount, basketProductTotal]);

  return {
    ...state,
    checkout: checkout || checkoutFactory(),
    checkoutInstanceSettings: checkoutInstanceSettings || checkoutInstanceSettingsFactory(),
    checkoutSession: checkoutSession || checkoutSessionFactory(),
    getCheckoutPayments,
  };
}
