import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/app";
import { isNumber } from "utils/helpers/checkout/validation";

// Handling validation of all types of inputs
export function useInputValidation() {
  const { appSettings } = useAppSelector();
  const { requiredGuestInformation } = appSettings;

  // Merging required input settings
  const requiredInputs = useMemo(() => {
    return {
      deliveryAddress: true, // Always required
      deliveryZipCode: true,
      ...requiredGuestInformation,
    };
  }, [requiredGuestInformation]);

  const isRequired = useCallback(
    (name: string): boolean => {
      const isValidInputName = (n: string): n is keyof typeof requiredInputs => n in requiredInputs;
      return isValidInputName(name) ? requiredInputs[name] : false;
    },
    [requiredInputs]
  );

  const inputIsValid = useCallback((name: string, value?: string) => {
    if (!value || typeof value !== "string") {
      // All inputs are valid if empty - required is checked elsewhere
      return true;
    } else {
      let validated = true;

      switch (name) {
        case "phone":
          let phoneIsNumber = isNumber(value);
          validated = value.length > 0 && value.length < 9 && phoneIsNumber;
          break;
        case "email":
          let symbolCount = (value.match(/@/g) || []).length; // Making sure there's only 1 @ symbol
          validated = value !== undefined ? value.length > 3 && value.length < 101 && value.includes("@") && value.includes(".") && symbolCount === 1 : false;
          break;
        case "zipCode" || "deliveryZipCode":
          let zipIsNumber = isNumber(value);
          validated = value.toString().length === 4 && zipIsNumber;
          break;
        default:
          validated = true;
      }
      return validated;
    }
  }, []);

  const getInputError = useCallback(
    (name: string, value?: string, requiredErrorMessage?: string) => {
      // If input is invalid, the error is shown regardless of required
      const isValid = inputIsValid(name, value);

      if (!isValid) {
        if (name === "phone") {
          return "errors:Input.InvalidPhone";
        }

        return "errors:Input.Invalid";
      }

      if (isRequired(name) && !value) {
        return requiredErrorMessage ? requiredErrorMessage : "";
      }

      return "";
    },
    [isRequired, inputIsValid]
  );

  return { getInputError };
}
