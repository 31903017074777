import { ILabelDto } from "@crunchit/types";
import Label from "./Label";
import LabelInfo from "./LabelInfo";
import "./Labels.scss";

export default function Labels(props: { labels: ILabelDto[] }) {
  const { labels } = props;

  return (
    <div className="labels">
      {labels.map((labels) => {
        return <Label key={labels.id} label={labels} />;
      })}
      {labels.length > 0 && <LabelInfo labels={labels} />}
    </div>
  );
}
