import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import "./RestaurantPickupInfo.scss";

export default function RestaurantPickupInfo() {
  const { productionUnit } = useAppSelector();
  const { t } = useTranslation();

  const streetAndCity = useMemo(() => {
    if (productionUnit && productionUnit.address) {
      return productionUnit.address.split(",");
    }
  }, [productionUnit]);

  if (!productionUnit) {
    return <></>;
  }

  return (
    <div className="restaurant-pickup-info">
      <h1>{t("checkout:Pickup.Title")}</h1>

      <article className="location">
        <h1>{productionUnit.name}</h1>

        {productionUnit.phone && <p className="location-phone">{productionUnit.phone}</p>}
        {streetAndCity && (
          <>
            <p className="location-street">{streetAndCity[0]}</p>
            <p className="location-city">
              {productionUnit.zipCode && <span>{productionUnit.zipCode}</span>}
              {streetAndCity[1]}
            </p>
          </>
        )}
      </article>
    </div>
  );
}
