import { useAppSelector } from "store/app";
import smileySrc from "assets/img/smiley.png";
import "./Smiley.scss";

export default function Smiley() {
  const { appSettings } = useAppSelector();

  return (
    <a className="smiley" href={appSettings.smileyUrl} target="_blank" rel="noopener noreferrer">
      <img src={smileySrc} alt="" />
    </a>
  );
}
