import { useTranslation } from "react-i18next";

import { useDiscountValidation } from "./useDiscountValidation";
import DiscountGiftcardInput from "./DiscountGiftcardInput";

interface IDiscountProps {
  discountInstanceId: string;
}

export default function Discount(props: IDiscountProps) {
  const { discountInstanceId } = props;
  const { t } = useTranslation();

  const { discountCode, amount, validationIsLoading, errorMessageKey, handleDiscountValidation, resetDiscountSession } = useDiscountValidation(discountInstanceId);

  return (
    <div className="discount-container">
      <DiscountGiftcardInput validatedCode={discountCode} validatedAmount={amount} validationIsLoading={validationIsLoading} errorMessageKey={errorMessageKey} placeholder={t("checkout:Discount.Input")} handleValidation={handleDiscountValidation} resetSession={resetDiscountSession} />
    </div>
  );
}
